import React, {useState, useEffect} from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import Img from "gatsby-image";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Breadscrum from "../components/Breadscrum";
import ArticleCta from "../components/ArticleCta";
import PopUp from "../components/PopUp";
import PopUpZaveti from "../components/PopUpZaveti";
import CenovaKalkulacka from "../components/CenovaKalkulacka";
import ArticleWidget from "../components/ArticleWidget";
import ArticleFormular from "../components/ArticleFormular";
import ArticleWidgetZaveti from "../components/ArticleWidgetZaveti";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import LinkButton from "../components/pohrby/LinkButton";
import LinkButtonV2 from "../components/brandingV2/LinkButtonV2";


export const query = graphql`
  query($slug: String!) {
    allContentfulAsset {
      nodes {
        file {
          url
          contentType
        }
        fluid(maxWidth: 930, maxHeight: 600) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }

    allContentfulObradniSin(filter: {slug: {eq: $slug }}) {
      edges {
        node {
          id
          slug
          seoTitle

          perex2 {
            perex2
          }
          adresa
          spojeni
          gps {
            lat
            lon
          }
          projekceFotoVideo
          

          seoDesc {
            seoDesc
          }
          imageSeoAlt
          heading
          content {
            json
          }
          image {
            fixed(width: 350) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;


const ObradniSinTemplateInner = styled.section`  
  .wrapper {
    max-width: 1150px;
    width: calc(100% - 50px);
    margin: 0 auto;
    padding: 40px 0;

    h1 {
      margin-top: 20px;
      font-size: 36px;
      line-height: 47px;
      font-family: NewKansas;
      color: #243A58;

      @media(max-width: 860px) {
        font-size: 30px;
        line-height: 37px;
      }
    }

    h2 {
      font-size: 25px;
      font-family: Visuelt-Medium;
      margin-bottom: 10px;
      margin-top: 30px;
      color: #243A58;
    }

    .perex {
      font-family: Visuelt-Regular;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #394B5C;
      line-height: 28px;

      @media(max-width: 860px) {
        font-size: 17px;
        line-height: 26px;
      }
    }

    .hlavniFoto {
      margin-top: 20px;
      max-width: 100%;
      margin-bottom: 15px;
      border-radius: 5px;
    }

    .contentWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media(max-width: 860px) {
        display: block;
      }

      .left {
        width: calc(100% - 350px - 80px);

        @media(max-width: 1000px) {
          width: calc(100% - 280px - 50px);
        }

        @media(max-width: 860px) {
          width: 100%;
        }
      }

      .right {
        width: 350px;

        @media(max-width: 1000px) {
          width: calc(280px);
        }

        @media(max-width: 860px) {
          width: 100%;
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          line-height: 25px;
          color: #394B5C;
          margin-bottom: 15px;

          span {
            font-family: Visuelt-bold;
          }

          a {
            color: #394B5C;
          }
        }
      }
    }

    .content {


      .foto {
        margin-top: 20px;
        max-width: 350px;
        margin-bottom: 20px;
        border-radius: 10px;
        width: 100%;
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        line-height: 25px;
        color: #394B5C;
        margin-bottom: 20px;
      }

      a {
        color: #394B5C;
      }

      h2 {
        margin-bottom: 20px;
        margin-top: 30px;
      }

      ul {
        padding-left: 25px;
      }

      ol {
        padding-left: 25px;
      }

      b {
        font-family: Visuelt-Bold;
        font-weight: normal;
      }
    }
  }
`;

const ObradniSinTemplate = (props) => {
  const data = props.data.allContentfulObradniSin.edges[0].node;


  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": data.heading,
    "image": [
      data.image.fixed.src,
      ],
    "author": {
      "@type": "Person",
      "name": "Goodbye.cz",
    },
    "publisher": {
      "@type": "Organization",
      "name": "Goodbye.cz",
      "logo": {
        "@type": "ImageObject",
        "url": "https://i.ibb.co/5nW0Cb6/logo-b.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://goodbye.cz/obradni-sine/${data.slug}/`,
    },
  }



  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const fluid = props.data.allContentfulAsset.nodes.find(n => n.file.url === node.data.target.fields.file["en-US"].url).fluid;
        return (
          <Img className="foto" alt={data.heading} fluid={fluid} />
        );
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return (
          <div className="videoWrapperOuter">
            <div className="videoWrapper" dangerouslySetInnerHTML={{__html: node.data.target.fields.code["en-US"]}} />
          </div>
        );
      },

      [BLOCKS.HEADING_2]: node => {
        return (
          <h2 id={node.content[0].value}>{node.content[0].value}</h2>
        );
      },

      [INLINES.HYPERLINK]: node => {
        return (
          <Link to={node.data.uri}>{node.content[0].value}</Link>
        );
      },

    },
  };


  return (
    <Layout V2 zadnaPodpora>
      
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        customTitle={true}
        image={`https:${data.image.fixed.src}`}
        article={true}
      />

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}} />

      <ObradniSinTemplateInner>
        
        <div className="wrapper">
          <Breadscrum
            visible={true}
            stranky={data.adresa.includes("Praha") ? 
              [
                {name: "Domů", url: "/"},
                {name: "Pohřební služba Praha", url: "/pohrebni-sluzba-praha/"},
                {name: "Obřadní síně", url: "/obradni-sine/"},
                {name: data.heading, url: `/obradni-sine/${data.slug}/`},
              ]
            :
              [
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Obřadní síně", url: "/obradni-sine/"},
                {name: data.heading, url: `/obradni-sine/${data.slug}/`},
              ]
            }
          />
          
          <div className="contentWrapper">
            <div className="left">
              <h1>{data.heading}</h1>
              <p className="perex">{data.perex2.perex2}</p>
              <Img className="hlavniFoto" alt={data.imageSeoAlt} fixed={data.image.fixed} />
              <h2>O síni</h2>
              <div className="content">
                {documentToReactComponents(data.content.json, options)}
              </div>
            </div>

            <div className="right">
              
              <h2>Objednat pohřeb</h2>

              <p>Objednejte si pohřeb v této obřadní síni {data.adresa.includes("Praha") ? <>v <Link to="/pohrebni-sluzba-praha/">pohřební službě Praha</Link></> : <>u naší <Link to="/pohrebni-sluzba/">pohřební služby</Link></>}</p>
              <LinkButtonV2 to="/nabidka/dotaznik/" text={data.adresa.includes("Praha") ? "Začít plánovat pohřeb v Praze" : "Začít plánovat pohřeb"} />

              <h2>Spojení</h2>
              <p>{data.spojeni}</p>

              <h2>Kde ji najdete?</h2>
              <p><span>Adresa:</span> {data.adresa}</p>
              <p><span>GPS:</span> {data.gps.lat}, {data.gps.lon}</p>

              <h2>Mapa</h2>
              <iframe
                frameBorder="0"
                title={data.adresa}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDR-wPIwbrQ2srKQ5FYSRXXCxdbPgAFhS4&q=${data.adresa}`}>
              </iframe>

              <h2>Projekce, foto, video...</h2>
              <p>{data.projekceFotoVideo}</p>

              
              
            </div>
            
          </div>

          




        </div>

        
      </ObradniSinTemplateInner>
      <SeVsimPomuzeme />
    </Layout>
  );
}

export default ObradniSinTemplate;
